.logosWrapper {
  margin-bottom: 20px;
}

.logosContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (--tablet) {
    flex-direction: row;
    align-items: center;
  }
}

.image {
  position: relative;

  @media (--tablet) {
    flex: 1;
  }
  /* overriding Next JS Image inline CSS to preserve the image ratio */
  img {
    height: auto !important;
    position: static !important;
  }
}
