.tpSlider {
  :global(.trustpilot-widget[data-review-languages='de']) {
    & > iframe {
      height: 235px !important;
    }

    @media (--tablet) {
      & > iframe {
        height: 240px !important;
      }
    }
  }

  :global(.trustpilot-widget) {
    @media (--tablet) {
      & > iframe {
        height: 140px !important;
      }
    }

    @media (max-width: 575px) {
      & > iframe {
        height: 300px !important;
      }
    }
  }
}
