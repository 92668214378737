.landingPageBlock {
  & + & {
    margin-top: -80px;
  }

  & .contentWrapper {
    padding-top: var(--spacing-20);
    padding-bottom: var(--spacing-20);
  }

  &[data-theme='borderless'] {
    background-color: var(--color-white);

    & .contentWrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.contentWrapper {
  && {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-10) var(--spacing-5);

    @media (--tablet-l) {
      flex-direction: row;
      padding-right: var(--spacing-8);
      padding-left: var(--spacing-8);
    }
    @media (--desktop-l) {
      padding-right: var(--spacing-16);
      padding-left: var(--spacing-16);
    }

    &.imageAlignRight {
      @media (--tablet-l) {
        flex-direction: row-reverse;
      }

      & .copyContainer {
        padding-left: 0;

        @media (--tablet-l) {
          padding-right: var(--spacing-10);
        }
      }
    }
  }
}
.imageContainer {
  position: relative;
  margin-bottom: var(--spacing-6);
  @media (--tablet-l) {
    flex: 1 1 50%;
    margin-bottom: 0;
  }

  & img {
    object-fit: contain;
    position: relative !important;
  }
}
.copyContainer {
  @media (--tablet-l) {
    flex: 1 1 50%;
    padding-left: var(--spacing-10);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & h3 {
    && {
      margin-top: 0;
    }
  }
}

.headline {
  font-size: 24px;
  @media (--tablet) {
    font-size: 28px;
  }
}

.cta {
  width: 100%;
  max-width: initial;

  @media (--tablet-l) {
    max-width: 310px;
  }
}

.copy {
  margin-bottom: var(--spacing-8);
}

.headerLogo {
  position: relative;
  width: 260px;
  margin-bottom: var(--spacing-10);

  img {
    position: relative !important;

    @media (--tablet) {
      position: absolute;
    }
  }
}

.hideSectionForMobile {
  display: none;
  @media (--tablet-l) {
    display: block;
  }
}

.hideSectionForTblt {
  display: block;
  @media (--tablet-l) {
    display: none;
  }
}
