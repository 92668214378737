.promoBox {
  --box-background: var(--color-primary-500);
  --box-text-color: #fff;

  & + & {
    margin-top: calc(-1 * var(--spacing-10));
  }
}

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &[data-image-side='right'] {
    flex-direction: row-reverse;

    & .content {
      padding-right: var(--spacing-20);
      padding-left: var(--spacing-10);

      @media (--mobile) {
        padding: var(--spacing-6);
      }
    }
  }
}

.image {
  display: flex;
  flex: 1 1 280px;
  position: relative;
  min-height: 340px;

  @media (--mobile) {
    min-height: 200px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-1);
  flex: 1 1 320px;
  background-color: var(--box-background);
  color: var(--box-text-color);
  currentcolor: var(--box-text-color);
  padding: var(--spacing-10);
  padding-left: var(--spacing-20);

  @media (--mobile) {
    padding: var(--spacing-6);
  }

  & h2 {
    margin-bottom: var(--spacing-4) !important;

    @media (--mobile) {
      font-size: 24px;
    }
  }

  & a {
    color: var(--box-text-color);
    transition: 0.1s;

    &:hover,
    &:active,
    &:focus {
      color: inherit;
    }
  }
}
