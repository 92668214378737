.wrapper {
  display: flex;
  max-width: 100%;
  flex-direction: column;

  @media (--tablet-l) {
    flex-direction: row;
    &.imgRight {
      flex-direction: row-reverse;
      & .contentWrapper {
        padding: 70px var(--spacing-20) 50px 0;
      }
    }
  }
}
.imageWrapper {
  width: 100%;
  position: relative;
  height: 260px;
  flex: 0 0 50%;
  aspect-ratio: 2 / 1;
  @media (--tablet-s) {
    aspect-ratio: 3 / 1;
  }
  @media (--tablet-l) {
    aspect-ratio: unset;

    height: 474px;
    max-width: 642px;
  }
}

.contentWrapper {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--spacing-6);
  @media (--tablet-l) {
    padding: 70px 0px 50px var(--spacing-20);
    align-items: flex-start;
  }
  & p {
    margin: 0;
  }
  && h2 {
    margin-bottom: var(--spacing-6);
    @media (--tablet-s) {
      margin-bottom: var(--spacing-6);
    }
  }
}

.usps {
  margin: 0;
  padding: var(--spacing-6) 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 700;
  gap: var(--spacing-4) var(--spacing-10);

  @media (--tablet-l) {
    padding: var(--spacing-4) 0;
  }
  & li {
    margin-bottom: var(--spacing-2);
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    &:last-child {
      flex: 0 0 100%;
    }
    &:nth-of-type(1n + 4) {
      display: none;
    }
  }
}
