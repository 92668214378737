.content {
  padding-top: var(--spacing-10);

  & .byline {
    margin: 0;
  }

  @media (--desktop) {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &&.noCopy {
      justify-content: center;
    }
  }

  & .ctaBlock {
    display: flex;
    text-align: center;
    flex-direction: column;
    & a {
      white-space: normal;
    }
    @media (--desktop) {
      gap: var(--spacing-8);
      flex-direction: row;
    }
  }

  & .byline + .ctaBlock {
    margin-top: var(--spacing-6);
    @media (--desktop) {
      margin-top: 0;
    }
  }
}
