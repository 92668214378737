.tableComponent {
  --background-color-column: var(--color-primary-100);
  --background-color-table: var(--color-primary-50);

  background-color: var(--background-color-table);
  & .container {
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
  }

  & .header {
    margin: 0 0 var(--spacing-4);
  }

  & .description {
    & p {
      margin-top: 0;
      margin-bottom: var(--spacing-5);
    }
  }
  [data-tenant='rac'] & {
    --background-color-table: var(--color-rac-blue-10);
    --background-color-column: var(--color-rac-blue-20);
  }
}

.customTable {
  table {
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    border-spacing: var(--spacing-5) 0;
    display: block;
    white-space: nowrap;
    table-layout: fixed;
    @media (--desktop) {
      border-spacing: 0;
    }
  }

  h5 {
    font-size: 16px;
    margin: var(--spacing-4) 0 var(--spacing-2);
    @media (--tablet-s) {
      font-size: 20px;
    }
  }

  & td {
    padding: var(--spacing-4) var(--spacing-8) var(--spacing-4);
    background-color: var(--background-color-column);
    scroll-snap-align: start;
    width: 1%;
  }
  & tr:last-of-type {
    td {
      padding-bottom: var(--spacing-10);
    }
  }

  & td:nth-child(even) {
    @media (--desktop) {
      background-color: transparent;
    }
  }
}
